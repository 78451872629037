@import "variables";

.g-component {
	margin-bottom: 40px;

	&[data-gtm-promo-name="Customer Service"] .g-promotional-row-style-2__inner {
		padding: {
			bottom: 0;
			top: $unit-small;
		};
	}

	@include media ( ">=medium" ) {

		.g-promo-grid__small-top {
			margin-bottom: 0;
		}
	}

	@include media ( ">=xlarge" ) {

		&:not(.g-hero-style-1) {
			margin-bottom: 60px;
		}

		&.g-cl-carousel[data-gtm-promo-name="Local Stuff City State"] {
			margin-bottom: 80px;
		}

		.g-promo-grid__small-top {
			margin-bottom: 0;
		}
	}
}

.g-cl-carousel {

	.g-carousel--shop_by_league_two {
	
		@include media ( ">=xxlarge" ) {

			margin-left: 150px;
			margin-right: 150px;
		}

		@include media ( ">=xxxlarge" ) {

			margin-left: 250px;
			margin-right: 250px;
		}

		.g-promotional-card__image-source {
			max-height: 106px;
		}
	}

	.g-carousel--shop_by_brand_two {

		@include media ( ">=xxxlarge" ) {

			margin-left: 50px;
			margin-right: 50px;
		}

		.g-carousel__slide {
			justify-content: center;
			text-align: center;
		}

		.g-promotional-card[ data-gtm-promo-id*="shop_by_47" ],
		.g-promotional-card[ data-gtm-promo-id*="shop_by_r" ] {

			.g-promotional-card__image-source {
				max-width: 80px;
			}
		}

		.g-promotional-card__image-source {
			max-width: 100px;
		}
	}
}
